var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"3","md":"3","offset-md":"9"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","prepend-inner-icon":_vm.icons.mdiMagnify,"label":"Search My History..","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline primary pa-3"},[_c('v-row',[_c('v-col',{staticClass:"white--text font-weight-bold",attrs:{"cols":"12","xs":"2","md":"2","align-self":"center"}},[_vm._v(" FILTER ")]),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('input-filter',{attrs:{"is_client":true,"input_data":_vm.input_data},on:{"input_data":_vm.filter_data}})],1)],1)],1),_c('v-card-text',{staticClass:"px-0"},[(_vm.isLoading)?_c('v-skeleton-loader',{staticClass:"mr-2 mt-6",attrs:{"type":"table"}}):_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.quotations.items,"sort-by":['updated_at'],"sort-desc":[true],"search":_vm.search,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.job_id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("#"+_vm._s(_vm.$tools.addLeadingZeros(item.job_id)))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [(item.updated_at !== null)?_c('div',[_vm._v(" "+_vm._s(_vm.$tools.formatDateTime(item.updated_at))+" ")]):_vm._e()]}},{key:"item.production_at",fn:function(ref){
var item = ref.item;
return [(item.production_at !== null)?_c('div',[_vm._v(" "+_vm._s(_vm.$tools.formatDateTime(item.production_at))+" ")]):_vm._e()]}},{key:"item.completed_at",fn:function(ref){
var item = ref.item;
return [(item.completed_at !== null)?_c('div',[_vm._v(" "+_vm._s(_vm.$tools.formatDateTime(item.completed_at))+" ")]):_vm._e()]}},{key:"item.file_count",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","small":"","color":_vm.statusColor(item.status)}},[_vm._v(" "+_vm._s(item.file_count)+" ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$tools.addCurrencySymbol(item.seller_amount?item.seller_amount:item.buyer_amount)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"text-capitalize",attrs:{"outlined":"","small":"","color":_vm.statusColor(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"primary rounded-pill",attrs:{"to":("/quotation-detail?uuid=" + (item.id)),"small":""}},[_vm._v("View Details")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-card-actions',{staticClass:"px-0"},[(!_vm.quotations.total)?_c('div'):_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"12"}},[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"3","md":"3"}}),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('v-pagination',{staticClass:"text-center",attrs:{"length":Math.ceil(_vm.quotations.total / _vm.selectRow)},on:{"input":_vm.handlePageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-col',{attrs:{"cols":"12","offset-sm":"1","offset-md":"1","xs":"3","sm":"2","md":"2"}},[_c('v-select',{staticClass:"px-3",attrs:{"items":_vm.rows,"hide-details":"auto","outlined":"","dense":""},model:{value:(_vm.selectRow),callback:function ($$v) {_vm.selectRow=$$v},expression:"selectRow"}})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }