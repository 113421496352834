<template>
  <v-row>
    <v-col cols="6" md="4">
      <v-select
        v-model="qstatus"
        :items="states"
        item-text="state"
        item-value="value"
        class="white"
        persistent-hint
        hide-details="auto"
        outlined
        dense
        @change="setStatus"
      ></v-select>
    </v-col>
    <!-- Date Filtering  -->
    <v-col cols="6" md="4">
      <v-menu
        ref="menuDates"
        v-model="menuDates"
        :close-on-content-click="false"
        :return-value.sync="dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            class="white"
            placeholder="Select Date Range"
            readonly
            outlined
            dense
            hide-details="auto"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="dates"
          color="primary"
          no-title
          scrollable
          :min="dates[0]"
          :max="currentDate()"
          range
        >
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="menuDates = false">
            Cancel
          </v-btn>
          <v-btn text color="secondary" @click="dates = []">
            Clean
          </v-btn>
          <v-btn text color="primary" @click="setDates">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>


<script>
import { mapState } from "vuex";
import { QuotaionStatus } from "@/utils/constant";

export default {
  props: {
    is_client: {
      type: Boolean,
      default: true
    },
    input_data: {
      type: Object,
      default() {
        return {}
      }
    },
  },

  computed: {
    ...mapState({
      dateRangeText: function(){
        return this.dates.join(" ~ ")
      }
    })
  },

  created(){
    this.emitting();
  },

  data(props){
    let states = []
    Object.keys(QuotaionStatus).forEach(key => {
      if(!["super","client","rejected","deleted"].includes(QuotaionStatus[key])){
        states.push({
          state: QuotaionStatus[key].toUpperCase(),
          value: QuotaionStatus[key]
        });
      }
    });

    return {
      states,
      menuDates: false,
      qstatus: props.input_data.status,
      dates: [props.input_data.start_date, props.input_data.end_date]
    };
  },

  methods: {
    setStatus(){
      this.emitting();
    },
    setDates(){
      this.$refs.menuDates.save(this.dates);
      this.emitting();
    },
    emitting(){
      let data = {}
      if(this.dates.length == 0){
        data = {
          start_date: this.before30Days(),
          end_date: this.currentDate(),
        }
      } else if(this.dates.length == 1){
        data = {
          start_date: this.dates[0],
          end_date: this.currentDate(),
        }
      } else{
        data = {
          start_date: this.dates[0],
          end_date: this.dates[1],
        }
      }
      data["status"] = this.qstatus;

      this.$emit("input_data", data)
    },
    currentDate(){
      return this.$tools.dateTimeToStr(false, "yyyy-MM-dd");
    },

    before30Days(){
      return this.$tools.pastDate(30, "yyyy-MM-dd");
    },
  },
};
</script>