<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-row>
        <v-col cols="12" xs="3" md="3"  offset-md="9">
          <v-text-field
            v-model="search"
            dense
            outlined
            :prepend-inner-icon="icons.mdiMagnify"
            label="Search My History.."
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      
      <v-row>
        <v-col cols="12" xs="12" md="12">
          <v-card>
            <v-card-title class="headline primary pa-3">
              <v-row>
                <v-col
                  cols="12" xs="2" md="2"
                  class="white--text font-weight-bold" align-self="center"
                >
                  FILTER
                </v-col>
                <v-col cols="12" md="8">
                  <input-filter
                    :is_client="true"
                    :input_data="input_data"
                    @input_data="filter_data">
                  </input-filter>
                </v-col>
              </v-row>
            </v-card-title>

            <v-card-text class="px-0">
              <v-skeleton-loader
                v-if="isLoading"
                type="table"
                class="mr-2 mt-6"
              >
              </v-skeleton-loader>
              <!-- table data here  -->
              <v-data-table
                v-else
                v-model="selected"
                :headers="headers"
                :items="quotations.items"
                :sort-by="['updated_at']"
                :sort-desc="[true]"
                :search="search"
                hide-default-footer
              >
                <template v-slot:[`item.job_id`]="{ item }">
                  <span>#{{ $tools.addLeadingZeros(item.job_id) }}</span>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <span>{{item.name}}</span>
                </template>
                <template v-slot:[`item.updated_at`]="{ item }">
                  <div v-if="item.updated_at !== null">
                    {{ $tools.formatDateTime(item.updated_at) }}
                  </div>
                </template>
                <template v-slot:[`item.production_at`]="{ item }">
                  <div v-if="item.production_at !== null">
                    {{ $tools.formatDateTime(item.production_at) }}
                  </div>
                </template>
                <template v-slot:[`item.completed_at`]="{ item }">
                  <div v-if="item.completed_at !== null">
                    {{ $tools.formatDateTime(item.completed_at) }}
                  </div>
                </template>
                <template v-slot:[`item.file_count`]="{ item }">
                  <v-chip outlined small :color="statusColor(item.status)">
                    {{ item.file_count }}
                  </v-chip>
                </template>
                <template v-slot:[`item.price`]="{ item }">
                  <span>{{ $tools.addCurrencySymbol(item.seller_amount?item.seller_amount:item.buyer_amount) }}</span>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip outlined small
                    class="text-capitalize"
                    :color="statusColor(item.status)">
                    {{ item.status }}
                  </v-chip>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-btn
                    :to="`/quotation-detail?uuid=${item.id}`"
                    class="primary rounded-pill"
                    small
                  >View Details</v-btn>
                </template>
              </v-data-table>
            </v-card-text>

            <v-card-actions class="px-0">
              <div v-if="!quotations.total"></div>
              <v-row v-else>
                <v-col class="py-0" cols="12" md="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" xs="12" sm="3" md="3"></v-col>
                <v-col cols="12" xs="12" sm="6" md="6">
                  <v-pagination
                    class="text-center"
                    v-model="page"
                    :length="Math.ceil(quotations.total / selectRow)"
                    @input="handlePageChange"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" offset-sm="1" offset-md="1" xs="3" sm="2" md="2">
                  <v-select
                    v-model="selectRow"
                    :items="rows"
                    hide-details="auto"
                    class="px-3"
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-actions>

          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { mdiMagnify,mdiEye } from '@mdi/js'
import { LOAD_ALL_QUOTATION, LOAD_QUOTATION_BY_ID, REFRESHTOKEN } from "@/store/_actiontypes";

import InputFilter from "./InputFilter";


export default {
  components: {
    "input-filter": InputFilter,
  },

  computed: {
    ...mapState({
      quotations: (state) => state.quotation.all,
      user: (state) => state.account.user,
      dateRangeText: function(){
        return this.dates.join(" ~ ")
      }
    }),
  },

  created(){
    this.getAllQuotation();
  },

  data(){
    return {
      isLoading: false,
      search: "",
      job_status: "",
      start_date: "",
      end_date: "",
      input_data: {
        start_date: this.before30Days(),
        end_date: this.currentDate(),
        status: "status"
      },
      icons: {
        mdiMagnify,
        mdiEye
      },

      dates: [],
      selected: [],
      page: 1,
      selectRow: 10,
      rows: [ 5, 10, 20 ],
      
      headers: [
        {
          text: "ID#",
          value: "job_id",
          align: "start",
          sortable: true
        },
        {
          text: "NAME",
          value: "job_name",
          align: "start",
          sortable: true
        },
        {
          text: "CREATED DATE",
          value: "updated_at",
          align: "start",
          sortable: true },
        {
          text: "PRODUCTION DATE",
          value: "production_at",
          align: "start",
          sortable: true
        },
        {
          text: "COMPLETION DATE",
          value: "completed_at",
          align: "start",
          sortable: true
        },
        {
          text: "TOTAL",
          value: "file_count",
          align: "start",
          sortable: true
        },
        {
          text: "Price",
          value: "price",
          align: "center",
          sortable: true
        },
        {
          text: "STATUS",
          value: "status",
          align: "start"
        },
        {
          text: "ACTIONS",
          value: "action",
          align: "start"
        },
      ],
    };
  },

  watch: {
    job_status(){
      if(this.job_status){
        this.page = 1;
      }
      if(this.quotations.total > this.selectRow){
        this.getAllQuotation()
      }
    },
    selectRow(){
      this.page = 1;
      if(this.quotations.total > this.selectRow){
        this.getAllQuotation()
      }
    },
  },

  methods: {
    ...mapActions("quotation", [LOAD_ALL_QUOTATION, LOAD_QUOTATION_BY_ID]),
    ...mapActions("account", [REFRESHTOKEN]),

    filter_data(data){
      this.start_date = this.input_data.start_date
      this.end_date = this.input_data.end_date
      this.job_status = this.input_data.status
      this.input_data = data;

      if(
        this.start_date != this.input_data.start_date ||
        this.end_date != this.input_data.end_date ||
        this.job_status != this.input_data.status
      ) this.getAllQuotation();
    },

    getAllQuotation(){
      this.isLoading = true;
      if(this.$tools.timeToNextToken() < 300){
        this.REFRESHTOKEN({
          refresh_token: this.user.refresh_token,
        }).then((response) => {
          this.getAllQuotationFunction();
        }, (error) => {
          error
        });
      } else {
        this.getAllQuotationFunction();
      }
    },

    getAllQuotationFunction(){
      this.LOAD_ALL_QUOTATION({
        start_date: this.input_data.start_date ,
        end_date: this.input_data.end_date,
        job_status: this.input_data.status=="status" ? "" : this.input_data.status,
        per_page: this.selectRow,
        page: this.page,
      }).then((response) => {
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
      });
    },

    handlePageChange(value){
      this.page = value
      if(this.quotations.total > this.selectRow){
        this.getAllQuotation()
      }
    },

    currentDate(){
      return this.$tools.dateTimeToStr(false, "yyyy-MM-dd");
    },

    before30Days(){
      return this.$tools.pastDate(30, "yyyy-MM-dd");
    },

    statusColor(status){
      const status_arr = {
        requested: "secondary",
        reviewed: "accent",
        accepted: "info",
        processing: "warning",
        delivered: "success",
        revision: "error",
        completed: "primary",
        rejected: "secondary",
        deleted: "secondary"
      }
      return status_arr[status]
    },
  },
};
</script>