<template>
  <div>
    <v-row v-if="isLoading" class="mt-0">
      <v-col cols="12" xs="8" md="8">
        <v-skeleton-loader
          height="300"
          type="image,list-item-two-line"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" xs="4" md="4">
        <v-skeleton-loader v-for="n in 8" :key="n"
          class="pb-2"
          type="list-item-avatar-two-line"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-else class="mt-0">
      <v-col cols="12" md="8" class="pt-15 pb-0">
        <input-filter :input_data="input_data" @input_data="filter_data"></input-filter>
      </v-col>

      <v-col cols="12" xs="8" md="8">
        <apexchart
          class="pt-2 white"
          :options="chart_render_data.chartOptions"
          :series="chart_render_data.series"
        ></apexchart>
      </v-col>
      
      <v-col cols="12" xs="4" md="4">
        <v-list v-if="buyer_data.length>0" subheader dense twoLine nav class="pt-2">
          <template v-for="(item, index) in buyer_data">
            <router-link :to="`/quotation-detail?uuid=${item.id}`">
              <v-list-item :key="index" style="height:3.2rem;">
                <v-list-item-avatar>
                  <v-img
                    :src="item.photo?$tools.getEnv('VUE_APP_FILE_SERVER')+item.photo:$tools.defaultImage()">
                  </v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.status"></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-list-item-action-text v-text="howLongAgo(item.time)"></v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
            </router-link>

            <v-divider
              v-if="index < buyer_data.length - 1"
              :key="index"
            ></v-divider>
          </template>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { LOAD_CHART_QUOTATION } from "@/store/_actiontypes";
import {howLongAgo} from "@/utils/dates"
import InputFilter from "./InputFilter";
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    "input-filter": InputFilter,
    apexchart: VueApexCharts,
  },

  data(){
    return {
      howLongAgo,
      status: "",
      start_date: null,
      end_date: null,
      chart_title: "REQUESTED",
      chart_name: "Selling",
      chart_data: [],
      chart_cats: [],
      buyer_data: [],
      chart_render_data: [],
      imgError: false,
      isLoading: false,
      
      input_data: {
        start_date: this.before30Days(),
        end_date: this.currentDate(),
        status: "requested"
      },
    };
  },

  computed: {
    ...mapState({
      allChartData: (state) => state.quotation.chart_data,
      user: (state) => state.account.user,
    }),
  },

  created(){
    this.getAllChartDataFunction();
  },

  methods: {
    ...mapActions("quotation", [LOAD_CHART_QUOTATION]),

    getAllChartDataFunction(){
      this.isLoading = true;

      this.LOAD_CHART_QUOTATION({
        start_date: this.input_data.start_date,
        end_date: this.input_data.end_date,
        status: this.input_data.status,
        }).then((res) => {
        this.isLoading = false;
        this.setData();
      }).catch((err) => {
        this.isLoading = false;
      });
    },

    filter_data(data){
      this.start_date = this.input_data.start_date
      this.end_date = this.input_data.end_date
      this.input_data = data;
      this.setData();

      if(
        this.start_date != this.input_data.start_date ||
        this.end_date != this.input_data.end_date
      ) this.getAllChartDataFunction();
    },

    setData(){
      this.chart_data = [];
      this.chart_cats = [];
      this.status = this.input_data.status
      this.chart_name = this.input_data.status.toUpperCase()
      this.chart_title = this.input_data.status.toUpperCase()
      this.buyer_data = this.allChartData.buyer_data;
      let chart_obj = this.allChartData.chart_data;

      if(chart_obj.hasOwnProperty(this.status)){
        chart_obj = chart_obj[this.status]
        Object.keys(chart_obj).forEach(key => {
          this.chart_cats.push(key);
          this.chart_data.push(chart_obj[key]["data"]);
        })
      } else{
        this.chart_data = [];
        this.chart_cats = [];
      }
      this.chart_render_data = this.chartData();
      
      let balance = {
        estimate: this.allChartData.estimate.toFixed(2),
        earnings: this.allChartData.earnings.toFixed(2)
      }
      this.$emit("balance", balance);
    },

    currentDate(){
      return this.$tools.dateTimeToStr(false, "yyyy-MM-dd");
    },

    before30Days(){
      return this.$tools.pastDate(30, "yyyy-MM-dd");
    },
    
    chartData(){
      return{
        series: [{
          name: this.chart_name,
          data: this.chart_data
        }],
        chartOptions: {
          chart: {
            height: 350,
            type: "line",
          },
          forecastDataPoints: {
            count: 0
          },
          stroke: {
            width: 2,
            curve: 'smooth'
          },
          xaxis: {
            type: 'datetime',
            categories: this.chart_cats,
            tickAmount: 5,
            labels: {
              formatter: function(value, timestamp, opts){
                return opts.dateFormatter(new Date(timestamp), 'dd MMM')
              }
            }
          },
          title: {
            text: this.chart_title,
            align: 'left',
            style: {
              fontSize: "16px",
              color: '#666'
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              gradientToColors: [ '#1dbf73'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100]
            },
          },
          yaxis: {
            min: 0,
            max: 100
          }
        },
      }
    }
  }
}
</script>
