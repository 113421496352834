<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-row>
        <v-col cols="12" xs="3" md="3">
          <h1 class="primary--text">Overview</h1>
        </v-col>
        <v-col v-if="is_client" cols="6" xs="3" md="3" offset-md="6" align-self="center">
          <v-btn
            small
            rounded
            class="primary float-right"
            to="/create-quotation"
          >
            Create New Job
          </v-btn>
        </v-col>
        <v-col v-else cols="12" xs="6" md="6" align-self="center" offset-md="3">
          <v-row no-gutters>
            <v-col cols="4" offset-md="4">
              <v-card class="pa-1 mr-3 text-center primary white--text font-weight-bold">
                <p class="mb-0">${{balance.earnings}}</p>
                <p class="mb-0">Earnings</p>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card class="pa-1 text-center primary white--text font-weight-bold">
                <p class="mb-0">${{balance.estimate}}</p>
                <p class="mb-0">Estimate</p>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      
      <v-row v-if="!is_client" class="mt-0">
        <v-col cols="12">
          <seller-view @balance="setBalance"></seller-view>
        </v-col>
      </v-row>

      <v-row v-else class="mt-0">
        <v-col cols="12">
          <clients-view></clients-view>
        </v-col>
      </v-row>

    </v-col>
  </v-row>
</template>

<script>
import { Role } from "@/utils/constant";
import SellerView from "./SellerView";
import ClientsView from "./ClientsView";


export default {
  components: {
    SellerView,
    ClientsView
  },

  data(){
    return {
      isLoading: false,
      is_client: this.$tools.authorize([Role.CLIENT]),
      balance: {
        estimate: "0.00",
        earnings: "0.00"
      }
    };
  },

  methods: {
    setBalance(balance){
      this.balance = balance;
    },

  },

};
</script>